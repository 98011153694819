import {
  Clock,
  HeaderIndicator,
  NavSubheader,
  useIsMinWidth,
  useSyncCSSProperty,
  type NavSubheaderProps,
} from "@app/design-system";
import getConfig from "next/config";
import useDimensions from "react-cool-dimensions";
import styled from "styled-components";
import { useDeveloperOptions } from "../../util/DeveloperOptionsProvider/DeveloperOptionsProvider";
import useIsOffline from "./useIsOffline";

const StyledContent = styled.div`
  flex: 1;
`;

const StyledIndicators = styled.div`
  display: flex;
  gap: 1rem;
`;

export const APP_NAV_SUBHEADER_HEIGHT_PROPERTY = "--app-nav-subheader-height";

const EnvironmentIndicator = () => {
  const { publicRuntimeConfig } = getConfig();

  const { ENVIRONMENT } = publicRuntimeConfig;

  const { options } = useDeveloperOptions();
  if (ENVIRONMENT === "prod") return null;

  if (!options.isEnvironmentIndicatorEnabled) return null;

  if (
    options.isEnvironmentIndicatorEnabled &&
    !options.isMockServiceWorkerEnabled
  ) {
    return (
      <HeaderIndicator variant="tenant" data-testid="tenant-indicator">
        Currently operating in the {ENVIRONMENT.toUpperCase()} environment
      </HeaderIndicator>
    );
  }

  return (
    <HeaderIndicator variant="tenant" data-testid="tenant-indicator">
      Currently operating in the {ENVIRONMENT.toUpperCase()} environment with
      request mocking enabled
    </HeaderIndicator>
  );
};

export const AppHeaderIndicators = () => {
  const isOffline = useIsOffline();

  return (
    <>
      <EnvironmentIndicator />
      {isOffline && (
        <HeaderIndicator variant="offline" data-testid="offline-indicator">
          You are currently operating offline.
        </HeaderIndicator>
      )}
    </>
  );
};

const AppNavSubheader = ({ children, ...props }: NavSubheaderProps) => {
  const { observe: subheaderRef, height } = useDimensions();

  useSyncCSSProperty({
    name: APP_NAV_SUBHEADER_HEIGHT_PROPERTY,
    value: `${height}px`,
  });

  const isTabletLandscapeAndAbove = useIsMinWidth("lg");

  return (
    <>
      <NavSubheader {...props} ref={subheaderRef}>
        <StyledContent>{children}</StyledContent>
        {isTabletLandscapeAndAbove && (
          <StyledIndicators>
            <Clock data-testid="app-bar-clock" />
          </StyledIndicators>
        )}
      </NavSubheader>
      <AppHeaderIndicators />
    </>
  );
};

export default AppNavSubheader;
